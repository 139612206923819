import React, { Component } from "react";
import VideoPost from "./video-post";
import PostService from "../../services/post-service";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";
import BlogHeader from "../structure/blog-header";
import BlogMenu from "../structure/blog-menu";

class VideoPlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: [],
      title: "Chargement...",
    };
  }
  displayPosts() {
    PostService.getVideoPosts()
      .then((response) => {
        this.setState({
          posts: response.data.arrPosts,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          posts: [],
          loading: false,
          title: error,
        });
      });
  }
  componentDidMount() {
    this.displayPosts(this.page);
  }
  render() {
    return (
      <div className="grid-container cross-bg">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1">
            <BlogMenu />
          </div>
          <div className="content-col-2">
            <main className="">
              {this.state.posts.length === 0 && this.state.loading && (
                <LoadingBar />
              )}
              {this.state.posts.length === 0 && !this.state.loading && (
                <ErrorBlock message={this.state.title} />
              )}
              {this.state.posts.length > 0 && !this.state.loading && (
                <section className="card-section">
                  <h2 className="text-center h5 mb-1">Playlist</h2>
                  {this.state.posts.map((post) => (
                    <VideoPost key={post.publicID} post={post} />
                  ))}
                </section>
              )}
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoPlayList;
