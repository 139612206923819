import React, { Component } from "react";
import BlogHeader from "../structure/blog-header";
import LabelCloud from "../structure/label-cloud";
import BlogMenu from "../structure/blog-menu";
import PostService from "../../services/post-service";
import VideoPost from "./video-post";

class RandomVideo extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      title: "Chargement...",
      loading: false,
    };
  }

  componentDidMount() {
    this.getLastVideoPost();
  }
  getLastVideoPost() {
    PostService.getRandomVideoPost()
      .then((response) => {
        this.setState({
          posts: response.data.arrPosts,
        });
      })
      .catch((error) => {});
  }
  render() {
    return (
      <div className="grid-container grid-container-index">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1"></div>
          <div className="content-col-2">
            <main className="">
              {this.state.posts.map((post) => (
                <VideoPost key={post.publicID} post={post} />
              ))}
            </main>
          </div>
          <div className="content-col-3">
            <LabelCloud />
          </div>
        </div>
        <div className="footer">
          <div className="footer-col-1">
            <BlogMenu />
          </div>
          <div className="footer-col-2">
            <nav className="pagination text-center">
              <ul></ul>
            </nav>
            <div className="text-center"></div>
          </div>
          <div className="footer-col-3"></div>
        </div>
      </div>
    );
  }
}

export default RandomVideo;
