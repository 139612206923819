import { useEffect } from "react";

const ScrollToTop = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("top").scrollIntoView({ behavior: "smooth" });
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return null;
};

export default ScrollToTop;
