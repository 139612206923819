import axios from 'axios';
import AuthenticationService from './authentication';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const cache = {};

const PostService = {
	clearCache(key = null) {
		if (key) {
			// Supprimer une entrée spécifique du cache
			delete cache[key];
		} else {
			// Vider tout le cache
			Object.keys(cache).forEach(k => delete cache[k]);
		}
	},
	getExternalPosts() {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/posts/tumblr.json`
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, null) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					if (
						typeof response.data.posts !== "undefined" &&
						response.data.posts !== null
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getPosts(index, nbPosts) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/posts/${index}/${nbPosts}-${process.env.REACT_APP_SECTION_ID}.json`
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, null) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					if (
						typeof response.data.results !== "undefined" &&
						typeof response.data.results.posts !== "undefined" &&
						response.data.results.posts !== null
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getCategoryPosts(index, id, nbPosts) {
		return new Promise((resolve, reject) => {
			//
			if (id === "") {
				reject(new Error("ID non défini"));
				return false;
			}
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/label/${id}/${index}/${nbPosts}-${process.env.REACT_APP_SECTION_ID}.json`
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, null) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					if (
						typeof response.data.results !== "undefined" &&
						typeof response.data.results.posts !== "undefined" &&
						response.data.results.posts !== null
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getPost(publicID, sectionID) {
		if (typeof sectionID == 'undefined') {
			sectionID = 0;
		}
		const data = {
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			data: {
				sectionID: sectionID,
				publicID: publicID
			}
		};
		return new Promise((resolve, reject) => {
			if (publicID === "") {
				reject(new Error("ID non défini"));
				return false;
			}
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/post/${publicID}.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					if (
						typeof response.data.results !== "undefined" &&
						typeof response.data.results.post !== "undefined" &&
						response.data.results.post !== null
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	searchPosts(searchData) {
		searchData["sectionID"] = process.env.REACT_APP_SECTION_ID;
		const data = {
			data: searchData,
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey()
		}
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/search-posts.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					if (
						typeof response.data.results !== "undefined" &&
						typeof response.data.results.posts !== "undefined" &&
						response.data.results.posts !== null
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	searchLabels(searchData) {
		const data = {
			data: {
				searchData,
				sectionID: process.env.REACT_APP_SECTION_ID
			},
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey()
		};
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/search-labels.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					if (
						typeof response.data.results != "undefined" &&
						typeof response.data.results.labels != "undefined"
					) {
						resolve(response);
					}
					else {
						reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getVideoPosts() {
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/playlistaleatoire.json`
			}).then(function (response) {
				if (
					typeof response.data !== "undefined" &&
					typeof response.data.arrPosts !== "undefined" &&
					response.data.arrPosts !== null
				) {
					resolve(response);
				}
				else {
					reject(typeof response.data.status !== "undefined" ? response.data.status : "Erreur rencontrée");
				}

			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getLastVideoPost() {
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/last-video.json`
			}).then(function (response) {
				resolve(response);
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getRandomVideoPost() {
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/random-video.json`
			}).then(function (response) {
				resolve(response);
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	deletePost(publicID) {
		const data = {
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1,
			data: {
				publicID: publicID
			}
		};

		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"));
				return false;
			}
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/delete-post.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	savePost(post) {
		if (post.publicID === "" || post.publicID === "0" || post.publicID === "NEW") {
			return this.insertPost(post);
		}
		return this.updatePost(post);
	},
	insertPost(post) {
		const postData = {
			data: {
				post: post,
				sectionID: post.site,
				publicID: post.publicID
			},
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1
		};

		if (post.publicID === "" || post.publicID === "0") {
			return new Promise((resolve, reject) => {
				if (post.title === "") {
					reject(new Error("Titre non défini"));
					return false;
				}
				if (AuthenticationService.checkAuthRequest(postData) === false) {
					reject(new Error("Non autorisé"));
					return false;
				}
				axios({
					method: 'post',
					url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/insert-post.json`,
					data: postData
				}).then(function (response) {
					if (AuthenticationService.checkResponse(response, postData.requestKey, true) === false) {
						reject(new Error(new Error("Erreur du serveur")));
					} else {
						resolve(response);
					}
				}).catch(
					function (error) {
						reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
						return false;
					}
				);
			})
		}
	},
	updatePost(post) {
		const postData = {
			data: {
				post: post,
				sectionID: post.site,
				publicID: post.publicID
			},
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1
		};

		if (post.publicID !== "" && post.publicID !== "0") {
			return new Promise((resolve, reject) => {
				if (post.title === "") {
					reject(new Error("Titre non défini"));
					return false;
				}
				if (AuthenticationService.checkAuthRequest(postData) === false) {
					reject(new Error("Non autorisé"));
					return false;
				}
				axios({
					method: 'post',
					url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/update-post.json`,
					data: postData
				}).then(function (response) {
					if (AuthenticationService.checkResponse(response, postData.requestKey) === false) {
						reject(new Error("Erreur du serveur"));
					} else {
						AuthenticationService.handleAuthResponse(response);
						resolve(response);
					}
				}).catch(
					function (error) {
						reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
					}
				);
			})
		}
	},
	addPostLabel(postID, labelID) {
		const data = {
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1,
			data: {
				postID: postID,
				labelID: labelID,
				sectionID: process.env.REACT_APP_SECTION_ID
			}
		};
		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé !"));
				return false;
			}
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/add-post-label.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	deletePostLabel(postID, labelID) {
		const data = {
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1,
			data: {
				postID: postID,
				labelID: labelID,
				sectionID: process.env.REACT_APP_SECTION_ID
			}
		};
		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"));
			}
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/delete-post-label.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getLabelsCloud(delayMs) {
		const cacheKey = `labels_${0}_${0}`;
		if (cache[cacheKey]) {
			console.log("get cache");
			return Promise.resolve(cache[cacheKey]); // Retourner les données mises en cache
		}

		return delay(delayMs).then(() => {
			return new Promise((resolve, reject) => {
				//
				axios({
					method: 'get',
					url: `${process.env.REACT_APP_SERVER_URL}/labels-cloud/${process.env.REACT_APP_SECTION_ID}.json`,
				}).then(function (response) {
					if (response.status !== 200) {
						reject(new Error("Erreur du serveur"));
					} else {
						cache[cacheKey] = response;
						resolve(response);
					}
				}).catch(
					function (error) {
						reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
					}
				);
			})
		});


	},
	getLabels() {
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/labels/${process.env.REACT_APP_SECTION_ID}.json`,
			}).then(function (response) {
				if (response.status !== 200) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	getPostComments(publicID) {
		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_SERVER_URL}/page-comments/${publicID}.json`,
			}).then(function (response) {
				if (response.status !== 200) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	postComment(commentData, initialDateTime) {
		const data = {
			data: {
				comment: commentData,
				publicID: commentData.id_post,
				sectionID: process.env.REACT_APP_SECTION_ID,
				initialDateTime: initialDateTime
			},
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey()
		};

		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				url: `${process.env.REACT_APP_SERVER_URL}/post-comment.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	postCommentResponse(comment, initialDateTime) {
		const data = {
			data: {
				comment: comment,
				publicID: comment.publicID,
				sectionID: process.env.REACT_APP_SECTION_ID,
				initialDateTime: initialDateTime
			},
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey()
		};
		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"));
			}

			axios({
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/comment-response.json `,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	deleteComment(publicID) {
		const data = {
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1,
			data: {
				publicID: publicID,
				sectionID: process.env.REACT_APP_SECTION_ID
			}
		};

		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"))
			}
			axios({
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/posts/comment-delete.json`,
				data: data
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					resolve(response);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	}
};

export default PostService;