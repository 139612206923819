import React, { Component } from "react";
import PostService from "../../services/post-service";

class PostCommentForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.initialDateTime = this.props.initialDateTime;
    this.state = this.getNewState(this.props.post.publicID);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  getNewState(idPost) {
    return {
      comment: this.preFillComment({
        person: {
          publicID: idPost,
          alias: "",
          name: "",
          firstName: "",
          url: "",
          token: idPost,
        },
        post: {
          publicID: idPost,
        },
        title: "Commentaire",
        body: "",
        publicIdPost: idPost,
        token: idPost,
      }),
      status: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.post.publicID !== prevProps.post.publicID) {
      const state = this.getNewState(this.props.post.publicID);
      this.setState(state);
    }
  }
  componentDidMount() {}
  preFillComment(comment) {
    if (typeof Storage !== "undefined") {
      const alias = localStorage.getItem(
        `comment_alias_${process.env.REACT_APP_NAME}`
      );
      if (alias !== null) {
        const url = localStorage.getItem(
          `comment_url_${process.env.REACT_APP_NAME}`
        );
        comment.person.alias = alias;
        comment.person.url = url === null ? "" : url;
      }
    }
    return comment;
  }
  handleChange(event) {
    const comment = this.state.comment;
    if (event.target.name === "alias") {
      comment.person.alias = event.target.value;
    }
    if (event.target.name === "url") {
      comment.person.url = event.target.value;
    }
    if (event.target.name === "body") {
      comment.body = event.target.value;
    }

    this.setState({
      comment: comment,
    });
  }
  handleSubmit(event) {
    if (this.state.comment.body === "") {
      this.setState({
        status: "Veuillez saisir un commentaire",
      });
      return false;
    }

    if (this.state.comment.person.alias === "") {
      this.setState({
        status: "Veuillez saisir un pseudo",
      });

      return false;
    } else {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(
          `comment_alias_${process.env.REACT_APP_NAME}`,
          this.state.comment.person.alias
        );
        localStorage.setItem(
          `comment_url_${process.env.REACT_APP_NAME}`,
          this.state.comment.person.url
        );
      }
    }

    const commentData = this.state.comment;

    PostService.postComment(commentData, this.initialDateTime)
      .then((response) => {
        if (
          typeof response.data.results !== "undefined" &&
          typeof response.data.results.comment !== "undefined"
        ) {
          commentData.body = "";
          this.setState({
            comment: commentData,
          });
        }

        if (typeof response.data.responseDate !== "undefined") {
          this.initialDateTime = response.data.responseDate;
        }

        if (typeof response.data.status !== "undefined") {
          this.setState({
            status: response.data.status,
          });
        }

        this.props.handlePostComment();
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });

    event.preventDefault();
    return true;
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="card">
          <div className="">
            <div>
              <fieldset>
                <div className="text-center card-divider h4">
                  <legend className="h5">Ajouter un commentaire</legend>
                </div>
                <div className="grid-x grid-padding-x">
                  <div className="medium-6 cell">
                    <label htmlFor="alias" className="required">
                      Nom/Pseudo
                      <input
                        type="text"
                        id="alias"
                        name="alias"
                        value={this.state.comment.person.alias}
                        onChange={this.handleChange}
                        required="required"
                        maxLength="100"
                      />
                    </label>
                  </div>
                  <div className="medium-6 cell">
                    <label htmlFor="url">
                      Adresse web (facultative)
                      <input
                        type="text"
                        id="url"
                        name="url"
                        value={this.state.comment.person.url}
                        onChange={this.handleChange}
                        maxLength="300"
                        placeholder="url"
                        inputMode="url"
                      />
                    </label>
                  </div>
                  <div className="medium-12 cell">
                    <label htmlFor="body" className="required">
                      Commentaire
                      <textarea
                        id="body"
                        name="body"
                        value={this.state.comment.body}
                        rows="5"
                        onChange={this.handleChange}
                        required="required"
                        maxLength="5000"
                        placeholder="Saisir un commentaire"
                      ></textarea>
                    </label>
                  </div>
                  <div className="medium-6 cell">
                    <button
                      type="submit"
                      id="submit"
                      className="button primary"
                      name="submit"
                    >
                      Envoyer
                    </button>
                    <p>{this.state.status}</p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PostCommentForm;
