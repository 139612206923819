import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";
import BlogHeader from "../structure/blog-header";
import MiniIndexPost from "./mini-index-post";
import LabelCloud from "../structure/label-cloud";
import BlogMenu from "../structure/blog-menu";
import HighlightIndexPost from "./higlight-index-post";
import ScrollToTop from "../scroll-top";
import Pagination from "../pagination";

class BlogIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      title: "Chargement...",
      loading: false,
      page: "1",
    };
    this.page = "1";
    this.previousPage = "0";
  }
  componentDidMount() {
    this.displayPage();
  }
  componentDidUpdate(prevProps) {
    this.displayPage(prevProps);
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    } else {
      this.page = "1";
    }
  }
  displayPage() {
    this.setPage();

    if (this.page !== this.previousPage) {
      this.displayPosts(this.page);
      this.previousPage = this.page;
    }
  }
  displayPosts(p) {
    if (p > 0) {
      try {
        this.setState({
          loading: true,
        });
        PostService.getPosts(p, 10)
          .then((responseData) => {
            console.log(responseData.data.results.pagination);
            this.setState({
              posts: responseData.data.results.posts,
              pagination: responseData.data.results.pagination,
              title: "index",
              page: `${responseData.data.results.p}`,
              loading: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              posts: [],
              pagination: [],
              title: error,
              loading: false,
              page: "1",
            });
          });
      } catch (e) {
        this.setState({
          posts: [],
          pagination: [],
          title: "Erreur",
          loading: false,
          page: "1",
        });
      }
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Aucune page définie",
        loading: false,
      });
    }
  }
  render() {
    return (
      <div className="grid-container grid-container-index">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1">
            {this.state.posts.length > 0 && (
              <HighlightIndexPost
                key={this.state.posts[0].publicID}
                post={this.state.posts[0]}
              />
            )}
          </div>
          <main className="content-col-2">
            {this.state.posts.length === 0 && this.state.loading && (
              <LoadingBar />
            )}
            {this.state.posts.length === 0 && !this.state.loading && (
              <ErrorBlock message={this.state.title} />
            )}
            {this.state.posts.length > 1 && (
              <section>
                {this.state.posts.slice(1).map((post) => (
                  <MiniIndexPost key={post.publicID} post={post} />
                ))}
              </section>
            )}
            <div className="d-md-none">
              <Pagination
                pages={this.state.pagination}
                route={"p"}
                currentPage={this.state.page}
              />
            </div>
          </main>
          <div className="content-col-3">
            <LabelCloud />
          </div>
        </div>
        <div className="footer">
          <div className="footer-col-1">
            <BlogMenu />
          </div>
          <div className="footer-col-2">
            <Pagination
              pages={this.state.pagination}
              route={"p"}
              currentPage={this.state.page}
            />
            <div className="text-center">
              {this.page > 1 && <Link to={`/p/1`}>Retour page 1</Link>}
            </div>
          </div>
          <div className="footer-col-3">
            {this.state.title !== "" && (
              <h2 className="text-center h5 mb-1 text-lowercase">
                <Link to={`/p/1`}>{this.state.title}</Link>
              </h2>
            )}
          </div>
          {!this.state.loading && <ScrollToTop />}
        </div>
      </div>
    );
  }
}

BlogIndex.propTypes = {
  page: PropTypes.string.isRequired,
};

export default BlogIndex;
