import React, { Component } from "react";
import AuthenticationService from "../../services/authentication";
import { Link } from "react-router-dom";
import BlogHeader from "../structure/blog-header";
import BlogMenu from "../structure/blog-menu";

class Logoff extends Component {
  constructor(props) {
    super(props);
    this.state = this.getCurrentState();
    this.setStatus = this.setStatus.bind(this);
  }
  componentDidMount() {
    this.logOffAction();
  }
  logOffAction() {
    if (AuthenticationService.getIsLocalUser()) {
      //logoff
      AuthenticationService.checkLogin(true)
        .then((message) => {
          this.setStatus(message);
          window.location.reload();
        })
        .catch((error) => {
          this.setStatus(error.message);
        });
    }
  }
  getCurrentState() {
    return {
      user: {
        username: AuthenticationService.getLocalUserName(),
        password: "",
        auth: AuthenticationService.getIsLocalUser(),
      },
      status: AuthenticationService.getCurrentStatus(),
    };
  }
  setStatus(status) {
    this.setState({
      status: status,
    });
  }
  render() {
    //window.scrollTo(0, 0);
    if (AuthenticationService.getIsLocalUser()) {
      return (
        <div className="grid-container grid-container-index">
          <BlogHeader />
          <main className="card">
            <article className="card-section post">
              <header className="post-header mb-2">
                <h1 className="h4">{this.state.status}</h1>
              </header>
            </article>
          </main>
        </div>
      );
    }
    return (
      <div className="grid-container grid-container-index">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1">
            <BlogMenu />
          </div>

          <article className="content-col-2">
            <header className="post-header mb-2"></header>
            <section className="">
              <h1 className="h4">{this.state.status}</h1>
              <Link className="button primary" to="/login">
                Connexion
              </Link>
            </section>
          </article>
          <div className="content-col-3"></div>
        </div>
      </div>
    );
  }
}

export default Logoff;
