import axios from 'axios';
import CryptoJS from "react-native-crypto-js";

const AuthenticationService = {
	login: function (user) {
		const that = this;
		const data = {
			data: {
				userName: user.username,
				password: user.password
			},
			requestKey: this.getRequestKey()
		};

		return new Promise((resolve, reject) => {
			//
			axios({
				method: 'post',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				url: `${process.env.REACT_APP_SERVER_URL}/gestion/api_login.jsonp`,
				data: data,
				withCredentials: false
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else if (typeof response.data.results !== "undefined") {
					that.handleAuthResponse(response);
					if (typeof response.data.results.message !== "undefined") {
						resolve(response.data.results);
					} else {
						reject(new Error("Erreur de ticket"));
					}
				} else if (typeof response.data.status !== "undefined" && typeof response.data.error !== "undefined") {
					reject(response.data.status);
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	handleAuthResponse(response) {
		let userKey = "";
		let userKeyExpirationDate = "";
		let userName = "";
		let publicKey = "";

		// reset
		this.setLocalCredentials(userName, userKey, userKeyExpirationDate, publicKey);

		if (typeof response.data.results !== "undefined" && typeof response.data.results.auth !== "undefined") {
			if (typeof response.data.results.auth.userKey !== "undefined") {
				userKey = response.data.results.auth.userKey;
				if (typeof response.data.results.auth.userKeyExpirationDate !== "undefined") {
					userKeyExpirationDate = response.data.results.auth.userKeyExpirationDate.date;
				}
				if (typeof response.data.results.auth.userName !== "undefined") {
					userName = response.data.results.auth.userName;
				}
				if (typeof response.data.results.auth.publicKey !== "undefined") {
					publicKey = response.data.results.auth.publicKey;
				}
			}
		}

		// set
		this.setLocalCredentials(userName, userKey, userKeyExpirationDate, publicKey);

		return userKey !== "";
	},
	checkLogin: function (logoff) {
		const userKey = this.getLocalUserKey();
		const userName = this.getLocalUserName();
		const that = this;
		const data = {
			data: {
				userKey: userKey,
				userName: userName
			},
			requestKey: this.getRequestKey()
		};

		return new Promise((resolve, reject) => {
			if (userKey === "") {
				return ("Erreur de ticket");
			}
			if (userName === "") {
				return ("Utilisateur inconnu");
			}

			const url = process.env.REACT_APP_SERVER_URL + ((logoff === true) ? "/gestion/api_logoff.jsonp" : "/gestion/api_test_token.jsonp");
			//
			axios({
				method: 'post',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				url: url,
				data: data,
				withCredentials: false,
				crossorigin: true
			}).then(function (response) {
				if (AuthenticationService.checkResponse(response, data.requestKey) === false) {
					reject(new Error("Erreur du serveur"));
				} else {
					that.handleAuthResponse(response);
					if (logoff === true) {
						that.setLocalCredentials("", "", "", "");
					}
					if (typeof response.data.results != "undefined" && typeof response.data.results.message !== "undefined") {
						resolve(response.data.results.message);
					} else {
						reject(new Error("Erreur de ticket"));
					}
				}
			}).catch(
				function (error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	setLocalCredentials: function (userName, userKey, userKeyExpirationDate, publicKey) {
		localStorage.setItem('userKey_' + process.env.REACT_APP_NAME, this.cryptString(userKey));
		localStorage.setItem('userName_' + process.env.REACT_APP_NAME, this.cryptString(userName));
		localStorage.setItem('publicKey_' + process.env.REACT_APP_NAME, (publicKey));
		localStorage.setItem('userActiveDate_' + process.env.REACT_APP_NAME, new Date());
		localStorage.setItem('userKeyExpirationDate_' + process.env.REACT_APP_NAME, this.cryptString(userKeyExpirationDate));
	},
	getLocalUserKey: function () {
		return this.decryptString(localStorage.getItem('userKey_' + process.env.REACT_APP_NAME));
	},
	checkLocalAuthUser: function () {
		return this.decryptString(localStorage.getItem('userKey_' + process.env.REACT_APP_NAME)) !== "";
	},
	getLocalUserName: function () {
		return this.decryptString(localStorage.getItem('userName_' + process.env.REACT_APP_NAME));
	},
	checkLocalUserKeyExpirationDate: function () {
		const cipherUserKeyExpirationDate = localStorage.getItem('userKeyExpirationDate_' + process.env.REACT_APP_NAME);
		if (cipherUserKeyExpirationDate !== null && cipherUserKeyExpirationDate !== "") {
			const userKeyExpirationDate = this.decryptString(cipherUserKeyExpirationDate);
			if (userKeyExpirationDate !== "") {
				const d = new Date(userKeyExpirationDate);
				const d1 = new Date();
				if (d1 < d) {
					return true;
				}
			}
		}
		return false;
	},
	decryptString(str) {
		if (typeof str !== "undefined" && str !== null && str !== "") {
			const bytes = CryptoJS.AES.decrypt(`${str}`, process.env.REACT_APP_CLIENT_ID);
			if (typeof bytes !== "undefined" && bytes !== null) {
				return bytes.toString(CryptoJS.enc.Utf8);
			}
		}
		return "";
	},
	cryptString(str) {
		if (typeof str !== "undefined" && str !== null && str !== "") {
			return CryptoJS.AES.encrypt(`${str}`, process.env.REACT_APP_CLIENT_ID).toString();
		}
		return "";
	},
	getIsLocalUser: function () {
		if (this.checkLocalAuthUser() && this.getLocalUserName() !== "") {
			return this.checkLocalUserKeyExpirationDate();
		} else {
			return false;
		}
	},
	getCurrentStatus: function () {
		if (this.checkLocalAuthUser() && this.getLocalUserName() !== "") {
			if (this.checkLocalUserKeyExpirationDate()) {
				return `${this.getLocalUserName()} : connecté`;
			} else {
				return `${this.getLocalUserName()} - Temps de connexion expiré`;
			}
		} else {
			return "Aucune connexion en cours";
		}
	},
	getPublicKey: function () {
		return (process.env.REACT_APP_PUBLIC_KEY);
	},
	getRequestKey: function () {
		const d = new Date();
		return `${this.cryptString(d.toISOString() + '_key_')}_key_${this.cryptString(process.env.REACT_APP_REQUEST_KEY)}`;
	},
	checkAuthRequest(data) {
		return (typeof data !== "undefined" &&
			data !== null &&
			typeof data.userKey !== "undefined" &&
			data.userKey !== null &&
			data.userKey !== "" &&
			typeof data.publicKey !== "undefined" &&
			data.publicKey !== "" &&
			typeof data.requestKey !== "undefined" &&
			data.requestKey !== ""
		);
	},
	checkResponse: function (response, intialRequestKey, checkAuthData) {
		try {
			if (response == null || typeof response === "undefined") {
				return false;
			}

			if (response.status >= 400) {
				console.log("http error");
				// http errors
				return false;
			}

			if (intialRequestKey === null) {
				return true;
			}

			if (typeof response.data === "undefined") {
				return false;
			}

			if (checkAuthData === true) {
				if (this.handleAuthResponse(response) === false) {
					return false;
				}
			}

			// check RequestKey
			if (typeof intialRequestKey !== "undefined" &&
				intialRequestKey !== null &&
				typeof response.data !== "undefined" &&
				typeof response.data.requestKey !== "undefined" &&
				response.data.requestKey !== null) {
				if (response.data.requestKey === intialRequestKey) {
					// date_key_request_key
					const parts = response.data.requestKey.split('_key_');
					if (parts == null) {
						return false;
					}

					if (parts.length > 1) {
						if (parts[0] === "") {
							return false;
						}

						// compare keys
						const appRequestKey = this.decryptString(parts[1]);
						if (appRequestKey !== process.env.REACT_APP_REQUEST_KEY) {
							return false;
						}
					}
					return true;
				}
			}
			return false;
		} catch (error) {
			return false;
		}
	}
};

export default AuthenticationService;