import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import BlogLabelsList from "./posts/blog-labels-list";
import Editor from "./admin/editor";
import AdminData from "./admin/admin-data";
import BlogHome from "./posts/blog-home";
import BlogIndexContainer from "./posts/blog-index-container";
import BlogPostContainer from "./post/blog-post-container";
import Login from "./admin/login";
import Logoff from "./admin/logoff";
import VideoPlayList from "./pages/video-playlist";
import SearchPosts from "./posts/search-posts";
import BlogFullIndexContainer from "./posts/blog-full-index-container";
import BlogCategoryIndexContainer from "./posts/blog-category-index-container";
import ExternalPosts from "./pages/external-posts";
import RandomVideo from "./pages/random-video";

class Layout extends Component {
  render() {
    return (
      <div id="top" className="">
        <Router>
          <Routes>
            <Route exact path="/" element={<BlogHome />} />
            <Route exact path="/index" element={<BlogHome />} />
            <Route exact path="/labels" element={<BlogLabelsList />} />
            <Route
              exact
              path="/liste-complete/:page"
              element={<BlogFullIndexContainer />}
            />
            <Route exact path="/p/:page" element={<BlogIndexContainer />} />
            <Route
              exact
              path="/categorie/:id"
              element={<BlogCategoryIndexContainer />}
            />
            <Route
              exact
              path="/categorie/:id/:page"
              element={<BlogCategoryIndexContainer />}
            />
            <Route exact path="/recherche" element={<SearchPosts />} />
            <Route exact path="/video" element={<RandomVideo />} />
            <Route
              exact
              path="/page/:publicID.html"
              element={<BlogPostContainer />}
            />
            <Route exact path="/video-playlist" element={<VideoPlayList />} />
            <Route exact path="/editor" element={<Editor />} />
            <Route exact path="/editor/:publicID" element={<Editor />} />
            <Route exact path="/draft/:draftKey" element={<Editor />} />
            <Route exact path="/admin-data" element={<AdminData />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/logoff" element={<Logoff />} />
            <Route exact path="/inspirations" element={<ExternalPosts />} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default Layout;
