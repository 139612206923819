import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostComments from "./post-comments";
import PostCommentForm from "./post-comment-form";
import PostService from "../../services/post-service";
import AuthenticationService from "../../services/authentication";
import Delayed from "../delayed";
import PropTypes from "prop-types";
import BlogHeader from "../structure/blog-header";
import BlogMenu from "../structure/blog-menu";
import ScrollToTop from "../scroll-top";

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.handlePostComment = this.handlePostComment.bind(this);
    this.previousId = "";
    this.id = "";
    this.state = {
      post: null,
      id: "",
      nbComments: 0,
      previousPost: null,
      nextPost: null,
      status: "Chargement...",
      initialDateTime: "",
      requestKey: "",
      loading: true,
    };
  }
  componentDidMount() {
    this.displayPost();
  }
  componentDidUpdate(prevProps) {
    if (this.props.publicID !== prevProps.publicID) {
      this.displayPost(prevProps);
    }
  }
  displayPost() {
    if (typeof this.props.publicID !== "undefined") {
      this.id = this.props.publicID;
    }

    if (this.id !== this.previousId) {
      this.getPost(this.id);
      this.previousId = this.id;
      //window.scrollTo(0, 0);
    }
  }
  getPost(id) {
    PostService.getPost(id)
      .then((response) => {
        if (
          typeof response.data.results !== "undefined" &&
          typeof response.data.results.post !== "undefined"
        ) {
          this.setState({
            post: response.data.results.post,
            nextPost: response.data.results.nextPost,
            previousPost: response.data.results.previousPost,
            initialDateTime: response.data.responseDate,
            requestKey: response.data.requestKey,
            loading: false,
          });
        } else {
          const status =
            typeof response.data.status !== "undefined"
              ? response.data.status
              : "Erreur rencontrée";
          this.setState({
            posts: null,
            status: status,
            initialDateTime: "",
          });
        }
      })
      .catch((error) => {
        this.setState({
          posts: null,
          status: error.message,
          initialDateTime: "",
        });
      });
  }
  handlePostComment() {
    this.setState({
      nbComments: this.state.nbComments + 1,
    });
  }
  render() {
    return (
      <div className="grid-container">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1 text-lowercase">
            <BlogMenu />
            <div>
              {this.state.post !== null &&
                AuthenticationService.checkLocalAuthUser() && (
                  <ul className="menu horizontal mb-3 mt-1">
                    <li>
                      <Link
                        className="text-lowercase button secondary"
                        to={`/editor/${this.state.post.publicID}`}
                      >
                        Modifier
                      </Link>
                    </li>
                  </ul>
                )}
            </div>
          </div>
          {this.state.post === null && (
            <article className="content-col-2">
              <div className="card-section">
                <h1 className="fs100">{this.state.status}</h1>
              </div>
            </article>
          )}
          {this.state.post !== null && (
            <main>
              <article className="content-col-2">
                <header>
                  <time
                    className="post-date mb-2"
                    dateTime={this.state.post.dateIso}
                    itemProp="datePublished"
                  >
                    {this.state.post.dateDay}
                  </time>
                  <h1
                    className="post-title mb-1 fs100 fs-inherit"
                    itemProp="name"
                  >
                    <Link
                      itemProp="url"
                      to={`/page/${this.state.post.publicID}.html`}
                    >
                      {this.state.post.title}
                    </Link>
                  </h1>
                  <meta content="0" itemProp="interactionCount" />
                </header>

                <section className="post-body" itemProp="articleBody">
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.post.body }}
                  />
                </section>

                <footer className="post-footer"></footer>
                {!this.state.loading && <ScrollToTop />}
              </article>
              <aside className="content-col-2">
                <PostCommentForm
                  post={this.state.post}
                  handlePostComment={this.handlePostComment}
                  nbComments={this.state.nbComments}
                  initialDateTime={this.state.initialDateTime}
                  requestKey={this.state.requestKey}
                />
                <Delayed waitBeforeShow={1000}>
                  <PostComments
                    post={this.state.post}
                    nbComments={this.state.nbComments}
                  />
                </Delayed>
              </aside>
            </main>
          )}

          <div className="content-col-3">
            {this.state.post && this.state.post.labels.length == 0 && (
              <nav className="mb-2">
                <ul className="text-lowercase labels-list menu vertical">
                  <li>
                    <Link className="" to={`/index`}>
                      Récents
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
            {this.state.post && this.state.post.labels.length > 0 && (
              <nav className="mb-2">
                <ul className="text-lowercase labels-list menu vertical">
                  <li>
                    <Link className="" to={`/index`}>
                      Récents
                    </Link>
                  </li>
                  {this.state.post.labels.map((label) => (
                    <li key={label.publicID}>
                      <Link to={`/categorie/${label.publicID}`}>
                        {label.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            )}

            {this.state.post && (
              <nav className="mb-2">
                <ul className="text-lowercase menu vertical">
                  {this.state.previousPost !== null && (
                    <li className="prev">
                      <Link
                        aria-label="pages précédente"
                        to={`/page/${this.state.previousPost.publicID}.html`}
                      >
                        {this.state.previousPost.title}
                      </Link>
                    </li>
                  )}
                  {this.state.nextPost !== null && (
                    <li className="next">
                      <Link
                        aria-label="page suivante"
                        to={`/page/${this.state.nextPost.publicID}.html`}
                      >
                        {this.state.nextPost.title}
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BlogPost.propTypes = {
  publicID: PropTypes.string.isRequired,
};

export default BlogPost;
