import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";
import BlogHeader from "../structure/blog-header";
import BlogMenu from "../structure/blog-menu";
import ScrollToTop from "../scroll-top";
import Pagination from "../pagination";
import LabelCloud from "../structure/label-cloud";

class BlogFullIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      loading: true,
      title: "Chargement...",
      currentPage: 1,
    };
    this.page = 1;
    this.previousPage = 0;
  }

  componentDidMount() {
    this.setPage();
    this.displayPosts(this.page);
    this.previousPage = this.page;
  }
  componentDidUpdate() {
    this.displayPage();
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    }
  }
  displayPage() {
    this.setPage();

    if (this.page !== this.previousPage) {
      this.displayPosts(this.page);
      this.previousPage = this.page;
    }
  }
  displayPosts(index) {
    if (index > 0) {
      this.setState({
        loading: true,
      });
      PostService.getPosts(index, 50)
        .then((responseData) => {
          const posts = responseData.data.results.posts;
          let previousPeriod = 0;
          for (let i = 0; i < posts.length; i++) {
            if (posts[i].period !== previousPeriod) {
              previousPeriod = posts[i].period;
            } else {
              posts[i].period = null;
            }
          }
          console.log("display posts");
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
            title: `Page ${responseData.data.results.p}`,
            currentPage: this.page,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            posts: [],
            pagination: [],
            title: error,
            currentPage: this.page,
            loading: false,
          });
        });
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Aucune page définie",
        currentPage: this.page,
      });
    }
  }
  render() {
    if (this.state.posts.length === 0 && this.state.loading) {
      return (
        <div className="grid-container">
          <BlogHeader />
          <main className="content">
            <div className="content-col-1">
              <BlogMenu />
            </div>
            <div className="content-col-2">
              <LoadingBar />
            </div>
          </main>
        </div>
      );
    }
    return (
      <div className="grid-container">
        <BlogHeader />
        <main className="content">
          <div className="content-col-1">
            <BlogMenu />

            <ul className="menu vertical labels-list text-lowercase">
              <li className="li-menu-1">
                <Link className="" to={`/recherche`}>
                  Recherche
                </Link>
              </li>
            </ul>
          </div>
          {this.state.posts.length === 0 && !this.state.loading && (
            <ErrorBlock message={this.state.title} />
          )}

          {this.state.posts.length > 0 && (
            <div className="content-col-2">
              <Pagination
                pages={this.state.pagination}
                route="liste-complete"
                currentPage={this.state.currentPage}
              />
              <div className="">
                <ul className="menu vertical mb-3">
                  {this.state.posts.map((post) => (
                    <li key={post.publicID}>
                      {post.period !== null && (
                        <div className="h6 ml-2 mt-2 mb-2">{post.period}</div>
                      )}
                      <div className="border-light">
                        <Link to={`/page/${post.publicID}.html`}>
                          {post.title} -{" "}
                          <time className="highlight-color">
                            {post.dateDay}
                          </time>
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <Pagination
                pages={this.state.pagination}
                route="liste-complete"
                currentPage={this.state.currentPage}
              />
              {!this.state.loading && <ScrollToTop />}
            </div>
          )}

          <div className="content-col-3">
            <LabelCloud />
          </div>
        </main>
      </div>
    );
  }
}

BlogFullIndex.propTypes = {
  page: PropTypes.string.isRequired,
};

export default BlogFullIndex;
