import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";

class LabelCloud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "Libellés",
      labels: [],
      id: 0,
    };
  }

  componentDidMount() {
    this.getLabelsCloud();
  }

  getLabelsCloud() {
    PostService.getLabelsCloud(1000)
      .then((response) => {
        response.data.results.labels.forEach((element) => {
          element.class = `label-size label-size-${element.class}`;
        });

        this.setState({
          labels: response.data.results.labels,
        });
      })
      .catch((error) => {
        this.setState({
          status: error.message,
          labels: [],
        });
      });
  }

  render() {
    return (
      <div>
        <section className="mb-2">
          <ul className="menu vertical mb-2 labels-list">
            <li>
              <Link className="highlight-color" to={`/index`}>
                Récents
              </Link>
            </li>
            {this.state.labels &&
              this.state.labels.map((label) => (
                <li key={label.publicID} className="li-menu-1">
                  <Link
                    className="link-category"
                    title={label.occurrences}
                    to={`/categorie/${label.publicID}`}
                  >
                    <span>{label.title} </span>
                    <span className="span-occurrences">
                      ({label.occurrences})
                    </span>
                  </Link>
                </li>
              ))}
            {this.state.labels && this.state.labels.length > 0 && (
              <li>
                <Link className={`highlight-color`} to={`/labels`}>
                  +
                </Link>
              </li>
            )}
          </ul>
        </section>
      </div>
    );
  }
}

/*
<p className="mtm text-center">
	<a className="text-underline" title="Liste des libellés sur ce blog"
		href="">
		Liste des libellés
	</a>
</p>
*/
export default LabelCloud;
