import React, { Component } from "react";
import PropTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";

class VideoView extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
    this.url = `https://www.youtube.com/embed/${this.post.codevideo}?fs=1&amp;rel=0&amp;modestbranding=0&amp;theme=light&amp;hl=fr&amp;autoplay=1;iv_load_policy=3&amp;cc_load_policy=1&amp;showinfo=0`;
    // 1. Ajouter un état pour gérer la visibilité de l'iframe
    this.state = {
      isIframeVisible: false,
    };
  }
  // 2. Fonction pour mettre à jour l'état lors du clic sur l'image
  handleImageClick = () => {
    this.setState({ isIframeVisible: true });
  };
  render() {
    return (
      <div>
        {this.post !== null && this.post.image.src && (
          <div>
            {!this.state.isIframeVisible && (
              <div className="">
                <figure className="video-thumbnail">
                  {/* 3. Appeler handleImageClick lors du clic sur l'image */}
                  <img
                    src={this.post.image.src}
                    width={this.post.image.width}
                    height={this.post.image.height}
                    onClick={this.handleImageClick}
                    alt="Video thumbnail"
                    className="img-fluid"
                  />
                  <div className="play-overlay" onClick={this.handleImageClick}>
                    <i className="bi bi-play-circle-fill play-icon"></i>
                  </div>
                </figure>
              </div>
            )}

            {/* 4. Afficher l'iframe seulement si isIframeVisible est true */}
            {this.state.isIframeVisible && (
              <iframe
                className=""
                title={this.post.codevideo}
                id={this.post.codevideo}
                width="560"
                height="315"
                src={this.url}
                allowFullScreen
              ></iframe>
            )}
          </div>
        )}
      </div>
    );
  }
}

VideoView.propTypes = {
  post: PropTypes.object.isRequired,
};

export default VideoView;
