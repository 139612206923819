import React, { Component } from "react";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";
import BlogHeader from "../structure/blog-header";
import LabelCloud from "../structure/label-cloud";
import BlogMenu from "../structure/blog-menu";
import MiniIndexPost from "./mini-index-post";
import HighlightIndexPost from "./higlight-index-post";
import ScrollToTop from "../scroll-top";
import Pagination from "../pagination";
import { Link } from "react-router-dom";

class BlogCategoryIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      page: "1",
      loading: true,
      title: "Chargement...",
      route: "",
    };
    this.page = "1";
    this.previousPage = "0";
    this.previousId = "";
    this.id = "";

    this.setPage();

    this.setId();
  }
  componentDidUpdate(prevProps) {
    this.displayPage(prevProps);
  }

  componentDidMount() {
    this.displayPage();
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    } else {
      this.page = "1";
    }
  }
  setId() {
    if (typeof this.props.id !== "undefined") {
      this.id = this.props.id;
    } else {
      this.id = "";
    }
  }
  displayPage(prevProps) {
    this.setId();
    this.setPage();

    if (this.id !== this.previousId) {
      this.setState({
        posts: [],
        title: "chargement...",
      });
    }
    if (this.page !== this.previousPage || this.id !== this.previousId) {
      this.displayPosts(this.page, this.id);
      this.previousPage = this.page;
      this.previousId = this.id;
    }
  }

  displayPosts(index, id) {
    if (index > 0 && id !== "") {
      this.setState({
        loading: true,
      });
      PostService.getCategoryPosts(index, this.id, 9)
        .then((responseData) => {
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
            title: responseData.data.results.title,
            page: `${responseData.data.results.p}`,
            loading: false,
            route: `categorie/${this.id}`,
          });
        })
        .catch((error) => {
          this.setState({
            posts: [],
            pagination: [],
            title: error,
            page: "1",
            loading: false,
            route: `categorie/${this.id}`,
          });
        });
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Catégorie non définie",
      });
    }
  }
  render() {
    return (
      <div className="grid-container grid-container-index">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1">
            {this.state.posts.length > 0 && (
              <div>
                <HighlightIndexPost
                  key={this.state.posts[0].publicID}
                  post={this.state.posts[0]}
                />
              </div>
            )}
          </div>
          <div className="content-col-2">
            <main className="page-main medium-9 columns cell padding-x">
              {this.state.posts.length === 0 && this.state.loading && (
                <LoadingBar />
              )}
              {this.state.posts.length === 0 && !this.state.loading && (
                <ErrorBlock message={this.state.title} />
              )}
              {this.state.posts.length > 1 && (
                <section>
                  {this.state.posts.slice(1).map((post) => (
                    <MiniIndexPost key={post.publicID} post={post} />
                  ))}
                </section>
              )}
            </main>
            <div className="d-md-none">
              <Pagination
                pages={this.state.pagination}
                route={this.state.route}
                currentPage={this.state.page}
              />
            </div>
          </div>
          <div className="content-col-3">
            <LabelCloud />
          </div>
        </div>
        <div className="footer">
          <div className="footer-col-1">
            <BlogMenu />
          </div>
          <div className="footer-col-2">
            <Pagination
              pages={this.state.pagination}
              route={this.state.route}
              currentPage={this.state.page}
            />
            {!this.state.loading && <ScrollToTop />}
          </div>
          <div className="footer-col-3">
            {this.state.title !== "" && (
              <h2 className="text-center h5 mb-1 text-lowercase">
                <Link
                  className=""
                  arial-label="page index"
                  to={`/${this.state.route}`}
                >
                  {this.state.title}
                </Link>
              </h2>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BlogCategoryIndex.propTypes = {
  page: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default BlogCategoryIndex;
