import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingBar from "../loading-bar";
import PostService from "../../services/post-service";
import BlogHeader from "../structure/blog-header";
import BlogMenu from "../structure/blog-menu";

class BlogLabelsList extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      labels: [],
      title: "Chargement...",
      loading: false,
      page: 1,
    };
    this.page = 1;
    this.previousPage = 0;
  }
  componentDidMount() {
    this.setState({
      labels: [],
      loading: true,
      title: "Chargement...",
    });
    this.displayPage();
  }
  displayPage() {
    this.displayLabels();
  }
  displayLabels(p) {
    PostService.getLabels(p, 11)
      .then((responseData) => {
        this.setState({
          labels: responseData.data.labels,
          loading: false,
          title: "",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          labels: [],
          page: 1,
          title: "",
        });
      });
  }
  render() {
    return (
      <div className="grid-container">
        <BlogHeader />
        <div className="content">
          <div className="content-col-1">
            <BlogMenu />
          </div>
          <main className="content-col-2">
            {this.state.labels &&
              this.state.labels.length === 0 &&
              this.state.loading && <LoadingBar />}
            <div className="card">
              <div className="p-1">
                <div className="card-section">
                  <ul className="menu vertical mb-3">
                    {this.state.labels &&
                      this.state.labels.map((label) => (
                        <li key={label.publicID} className={``}>
                          <Link to={`/categorie/${label.publicID}`}>
                            <span>{label.title} </span>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </main>
          <div className="content-col-2"></div>
        </div>
      </div>
    );
  }
}

export default BlogLabelsList;
