import React, { Component } from "react";
import { Link } from "react-router-dom";
import DraftPostService from "../../services/draft-post-service";

class BackupPostsList extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.draftCounter = 0;
    this.state = {
      drafts: [],
      historyPosts: [],
      publishedPosts: [],
      status: "",
    };
    this.deleteDrafts = this.deleteDrafts.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.displayDrafts = this.displayDrafts.bind(this);
    this.downloadDraft = this.downloadDraft.bind(this);
  }
  componentDidUpdate() {
    if (this.draftCounter !== this.props.draftCounter) {
      this.draftCounter = this.props.draftCounter;
      this.displayDrafts();
    }
  }
  componentDidMount() {
    this.displayDrafts();
  }
  displayDrafts() {
    if (DraftPostService.testLocalStorage()) {
      this.setState({
        drafts: DraftPostService.getDrafts(1),
        historyPosts: DraftPostService.getDrafts(3),
        publishedPosts: DraftPostService.getDrafts(2),
      });
    } else {
      this.setState({
        status: "Fonction non disponible",
      });
    }
  }
  saveDraft() {
    // new draft
    this.createDraft();
    this.displayDrafts();
  }
  createDraft() {
    this.props.post.type = 1;
    DraftPostService.createDraft(this.props.post, 1, -1);
    this.setState({
      status: "Brouillon créé",
    });
  }
  downloadDraft(draftKey) {
    DraftPostService.downloadDraft(draftKey);
    this.setState({
      status: "Brouillon téléchargé",
    });
  }
  deleteDraft(draftKey) {
    DraftPostService.deleteDraft(draftKey);
    this.displayDrafts();
    this.setState({
      status: "Brouillon supprimé",
    });
  }
  deleteDrafts = function () {
    DraftPostService.deleteDrafts();
    this.displayDrafts();
    this.setState({
      status: "Aucun brouillon disponible",
    });
  };
  downloadDrafts = function () {
    DraftPostService.downloadDrafts();
  };
  render() {
    return (
      <div className="post-body">
        <button
          type="button"
          className="button secondary"
          onClick={this.saveDraft}
          title="sauvegarde local de l'article en cours d'écriture"
        >
          Sauvegarder en brouillon
        </button>
        <p>{this.state.status}</p>

        {this.state.historyPosts.length > 0 && (
          <div className="table-scroll">
            <h4 className="h5">Brouillons</h4>

            <table className="list-vertical mb3">
              <tbody>
                {this.state.drafts.map((draft, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        className="style-lien"
                        to={`/draft/${draft.draftKey}`}
                      >
                        {draft.title}
                      </Link>
                    </td>
                    <td>
                      <span>{draft.date_creation}</span>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button warning"
                        onClick={() => this.deleteDraft(draft.draftKey)}
                      >
                        X
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button secondary"
                        onClick={() => this.downloadDraft(draft.draftKey)}
                      >
                        DL
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {this.state.historyPosts.length > 0 && (
          <div className="table-scroll">
            <h4 className="h5">Modifications récentes</h4>

            <table className="stack mb3">
              <tbody>
                {this.state.historyPosts.map((draft, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        className="style-lien"
                        to={`/draft/${draft.draftKey}`}
                      >
                        {draft.title}
                      </Link>
                    </td>
                    <td>
                      <span>{draft.date_creation}</span>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button warning"
                        onClick={() => this.deleteDraft(draft.draftKey)}
                      >
                        X
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button secondary"
                        onClick={() => this.downloadDraft(draft.draftKey)}
                      >
                        DL
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {this.state.publishedPosts.length > 0 && (
          <div className="table-scroll">
            <h4 className="h5">Récemment publiés</h4>

            <table className="stack mb3">
              <tbody>
                {this.state.publishedPosts.map((draft, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        className="style-lien"
                        to={`/editor/${draft.publicID}`}
                      >
                        {draft.title}
                      </Link>
                    </td>
                    <td>
                      <span>{draft.date_creation}</span>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button warning"
                        onClick={() => this.deleteDraft(draft.draftKey)}
                      >
                        X
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button secondary"
                        onClick={() => this.downloadDraft(draft.draftKey)}
                      >
                        DL
                      </button>
                    </td>
                    <td>
                      <Link to={`/page/${draft.publicID}.html`}>lien</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="card-section">
          <ol className="menu horizontal">
            <li className="mb-2">
              <button
                onClick={this.deleteDrafts}
                type="button"
                className="m-0_5 button alert"
                title="Effacer les brouillons enregistrés en local"
              >
                Effacer tout
              </button>
            </li>
            <li>
              <button
                onClick={this.downloadDrafts}
                type="button"
                className="m-0_5 button"
                title="Effacer les brouillons enregistrés en local"
              >
                Télécharger tout
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

/*
BackupPostsList.propTypes = {
	labels: PropTypes.array.isRequired, // Assuming labels is an array
	post: PropTypes.string.isRequired, // Assuming post is a string
	// Add other prop types as needed
};*/

export default BackupPostsList;
