import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";

class EditorPostsList extends Component {
  constructor(props) {
    super(props);
    this.updateCounter = 0;
    this.props = props;
    this.state = {
      posts: [],
    };
  }
  componentDidUpdate() {
    if (this.updateCounter !== this.props.updateCounter) {
      this.updateCounter = this.props.updateCounter;
      this.displayPosts(1);
    }
  }
  componentDidMount() {
    this.displayPosts(1);
  }

  displayPosts(index) {
    PostService.getPosts(index, 12)
      .then((responseData) => {
        if (
          typeof responseData.data.results !== "undefined" &&
          typeof responseData.data.results.posts !== "undefined"
        ) {
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
          });
        }
      })
      .catch((error) => {});
  }
  render() {
    return (
      <aside>
        <article className="mb-1 is-anchored is-at-top">
          <ol className="menu vertical mb-2">
            {this.state.posts.map((post) => (
              <li className="border-light" key={post.publicID}>
                <Link className="style-lien" to={`/editor/${post.publicID}`}>
                  {post.title}
                </Link>
              </li>
            ))}
          </ol>

          <nav className="pagination">
            <ul className="menu expanded">
              {[...Array(8)].map((_, index) => (
                <li key={index}>
                  <button
                    type="button"
                    className="button secondary"
                    onClick={() => this.displayPosts(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </article>
      </aside>
    );
  }
}

export default EditorPostsList;
