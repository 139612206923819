import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class MiniIndexPost extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
  }
  render() {
    return (
      <article
        key={this.post.publicID}
        className=""
        itemProp="blogPost"
        itemScope="itemscope"
        itemType="http://schema.org/BlogPosting"
      >
        <div className="mini-index-post">
          <Link to={`/page/${this.post.publicID}.html`}>
            <header>
              <h1 className="fs100" itemProp="name">
                {this.post.title}{" "}
                <time
                  className="fs100 highlight-color"
                  dateTime={this.post.dateIso}
                  itemProp="datePublished"
                >
                  {this.post.dateDay}
                </time>
              </h1>
            </header>
          </Link>
        </div>
      </article>
    );
  }
}

MiniIndexPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default MiniIndexPost;
