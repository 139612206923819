import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      currentPage: this.props.currentPage,
      route: this.props.route,
      pages: this.props.pages,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentPage !== this.props.currentPage ||
      prevProps.route !== this.props.route ||
      prevProps.pages !== this.props.pages
    ) {
      this.setState({
        currentPage: this.props.currentPage,
        route: this.props.route,
        pages: this.props.pages,
      });
    }
  }
  render() {
    return (
      <nav
        className="pagination mb-2 text-center"
        role="navigation"
        aria-label="Pagination"
      >
        <ul>
          {this.state.pages.map(
            (page, index) =>
              index === 0 &&
              page !== "" && (
                <li key="prev-1" className="prev">
                  <Link
                    aria-label="page suivante"
                    className="highlight-color"
                    to={`/${this.state.route}/${page}`}
                    title={page}
                  >
                    &lt;
                  </Link>
                </li>
              )
          )}
          {this.state.pages.map(
            (page, index) =>
              index === 0 &&
              page === "" && (
                <li aria-hidden="true" key="prev" className="light-gray">
                  <button className="light-gray" disabled>
                    <span className="light-gray">&lt;</span>
                  </button>
                </li>
              )
          )}
          <li key="next" className="next">
            <Link
              arial-label="page courante"
              to={`/${this.state.route}/${this.state.currentPage}`}
            >
              page {this.state.currentPage}
            </Link>
          </li>
          {this.state.pages.map(
            (page, index) =>
              index === 1 &&
              page !== "" && (
                <li key="next-1" className="next">
                  <Link
                    aria-label="page précédente"
                    to={`/${this.state.route}/${page}`}
                    title={page}
                  >
                    &gt;
                  </Link>
                </li>
              )
          )}

          {this.state.pages.map(
            (page, index) =>
              index === 1 &&
              page === "" && (
                <li aria-hidden="true" key="prev" className="light-gray">
                  <button className="light-gray" disabled>
                    <span className="light-gray">&gt;</span>
                  </button>
                </li>
              )
          )}
        </ul>
      </nav>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.array.isRequired,
  route: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default Pagination;
