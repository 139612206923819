import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class HighlightIndexPost extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
  }
  render() {
    return (
      <article
        key={this.post.publicID}
        className=""
        itemProp="blogPost"
        itemScope="itemscope"
        itemType="http://schema.org/BlogPosting"
      >
        <div className="">
          <div className="mb-2">
            <Link to={`/page/${this.post.publicID}.html`}>
              <div>
                <header>
                  <h1 className="fs100" itemProp="name">
                    {this.post.title}{" "}
                    <time
                      className="fs100 highlight-color"
                      dateTime={this.post.dateIso}
                      itemProp="datePublished"
                    >
                      {this.post.dateDay}
                    </time>
                  </h1>
                </header>
                {this.post.summary !== "" && (
                  <section className="post-summary" itemProp="articleBody">
                    {this.post.summary} [...]
                  </section>
                )}
              </div>
            </Link>
          </div>
          <Link
            className="button primary"
            to={`/page/${this.post.publicID}.html`}
          >
            Lire la suite
          </Link>
        </div>
      </article>
    );
  }
}

HighlightIndexPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default HighlightIndexPost;
