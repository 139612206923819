import React, { Component } from "react";
import { Link } from "react-router-dom";

class BlogHeader extends Component {
  render() {
    return (
      <header className="header">
        <div className="header-col-1">
          <Link className="" title="accueil du blog" rel="home" to="/">
            {process.env.REACT_APP_TITLE}
          </Link>
        </div>
        <div className="header-col-2 text-center">
          <Link
            className="fade-in-right"
            title="accueil du blog"
            rel="home"
            to="/"
          >
            {process.env.REACT_APP_SUBTITLE}
          </Link>
        </div>
        <div className="header-col-3"></div>
      </header>
    );
  }
}

export default BlogHeader;
