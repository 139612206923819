import React, { Component } from "react";
import { Link } from "react-router-dom";

class BlogMenu extends Component {
  render() {
    return (
      <nav>
        <ul className="menu vertical labels-list text-lowercase">
          <li className="li-menu-1">
            <Link className="" to={`/`}>
              Accueil
            </Link>
          </li>
          <li className="li-menu-1">
            <Link className="" to={`/liste-complete/1`}>
              Liste complète
            </Link>
          </li>
          <li className="li-menu-1">
            <Link className="" to={`/video-playlist`}>
              Musique
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default BlogMenu;
